import clsx from "clsx";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { StarsTitle } from "../../components/StarsTitle/StarsTitle";
import { ReglementPageContext } from "../../helpers/pagesContext";
import * as styles from "./reglement.module.css";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export default function Reglement() {
  const {
    contentfulReglement: {
      headTitleUn,
      headTitleDeux,
      regleTitle,
      regleDescription,
      regles,
      pointsUnit,
      video,
      regleFirstDescription,
    },
  } = useContext(ReglementPageContext);

  return (
    <div>
      <Header firstLine={headTitleUn} SecondLine={headTitleDeux} />
      <div className={styles.root}>
        <div>
          <StarsTitle title={regleTitle} />
          <div className={styles.regleFirstDescription}>
            {renderRichText(regleFirstDescription)}
          </div>
          <div className={styles.regleDescription}>{regleDescription}</div>
        </div>
        <div className={styles.rulesList}>
          {regles.map(({ title, points, description, illustration }, i) => {
            return (
              <div key={title} className={styles.regle}>
                <div className={styles.imageContainer}>
                  <GatsbyImage
                    className={styles.image}
                    image={illustration.gatsbyImageData}
                    alt={illustration.filename}
                  />
                </div>
                <div className={styles.rank}>
                  <div>{i + 1}</div>
                </div>
                <div>
                  <div className={styles.points}>
                    {points} {pointsUnit}
                  </div>
                  <div className={styles.description}>{description}</div>
                </div>
              </div>
            );
          })}
        </div>
        <iframe
          className={styles.iframe}
          src={video}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        />
      </div>
    </div>
  );
}
