// extracted by mini-css-extract-plugin
export var description = "reglement-module--description--079c4";
export var iframe = "reglement-module--iframe--c6b06";
export var image = "reglement-module--image--f6807";
export var imageContainer = "reglement-module--imageContainer--dd06b";
export var points = "reglement-module--points--9d2ed";
export var rank = "reglement-module--rank--c2ac4";
export var regle = "reglement-module--regle--ee87e";
export var regleDescription = "reglement-module--regleDescription--c3d31";
export var regleFirstDescription = "reglement-module--regleFirstDescription--14d23";
export var root = "reglement-module--root--5524e";
export var rulesList = "reglement-module--rulesList--073ae";
export var slideIn = "reglement-module--slideIn--5b85d";