import * as React from "react";
import ReglementScene from "../../scenes/Reglement/Reglement";
import { ReglementPageContext } from "../../helpers/pagesContext";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import { REGLEMENT } from "../../helpers/route";

export default function Reglement(props) {
  return (
    <ReglementPageContext.Provider value={props.data}>
      <Layout currentRoute={REGLEMENT}>
        <ReglementScene />
      </Layout>
    </ReglementPageContext.Provider>
  );
}

export const pageQuery = graphql`
  query {
    contentfulReglement {
      title
      headTitleUn
      headTitleDeux
      regleTitle
      regleFirstDescription {
        raw
      }
      regleDescription
      pointsUnit
      video
      regles {
        title
        points
        description
        illustration {
          gatsbyImageData
          filename
          url
          file {
            url
          }
        }
      }
    }
  }
`;
